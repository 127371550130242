import CloseButton from "@/components/Auth/CloseButton";
import Layout from "@/components/Layout";
import { AuthContextProvider, AuthContext } from "@/contexts/AuthContext";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useStores } from "hooks/useStores";
import { useMediaQuery } from "@/src/hooks/useMediaQuery";
import clsx from "clsx";

const AuthLayout = ({ promoBanner, fullWidth = false, ...props }) => {
  const { plan } = useSubscriptionPermission(null);
  const { authStore } = useStores();
  const { currentUser, state, isFetching } = authStore;
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const router = useRouter();

  // Redirect once logged in
  useEffect(() => {
    // Don't do anything if if the user is on /cancelled
    if (router.pathname.includes("/cancelled")) {
      return;
    }

    // user should not be able to access the welcome page if they are not logged in
    if (router.pathname.includes("/welcome") && state === "loggedOut") {
      router.push("/signup");
      sessionStorage.removeItem("previousUrl");
      sessionStorage.removeItem("previousUrlForce");
      return;
    }

    if (plan !== "fetching" && plan !== 0) {
      // Get previousUrl from local storage, or if it doesn't exist default to home as a safe default
      const previousUrl =
        sessionStorage.getItem("previousUrlForce") ||
        sessionStorage.getItem("previousUrl") ||
        "/";

      // If this is not a paid user, always force them to complete the onboarding
      if (!previousUrl.includes("/campaign") && plan !== 2) {
        // router.push("/welcome");
        // sessionStorage.removeItem("previousUrl");
        // sessionStorage.removeItem("previousUrlForce");
        return;
      }

      // If they came from the premium homepage, redirect them to the appropriate next step
      if (previousUrl == "/premium") {
        if (plan === 2) {
          router.push("/home");
        } else {
          router.push("/premium#sign-up");
        }
        sessionStorage.removeItem("previousUrl");
        sessionStorage.removeItem("previousUrlForce");

        router.push(previousUrl);
        sessionStorage.removeItem("previousUrl");
        sessionStorage.removeItem("previousUrlForce");
      }
    }
  }, [plan]);

  return (
    <Layout {...props}>
      <AuthContextProvider>
        <div className="relative flex min-h-full flex-1 bg-white text-zinc-900">
          <div className="absolute right-6 top-14 z-30 lg:left-12 lg:top-14">
            <CloseButton />
          </div>

          <div
            className={clsx(
              "relative z-20 flex w-full flex-col",
              !fullWidth && "lg:w-1/2"
            )}
          >
            <div className="flex flex-1">{props.children}</div>
          </div>
        </div>
      </AuthContextProvider>
    </Layout>
  );
};

export default observer(AuthLayout);
