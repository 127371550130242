import Loading from "@/components/Loading";
import clsx from "clsx";
import SubscriptionCards from "./SubscriptionCards";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "@/contexts/AuthContext";
import { useStores } from "@/hooks/useStores";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import { useRouter } from "next/router";
import { observer } from "mobx-react";

export const ChooseYourPlan = observer(() => {
  const [couponCode, setCouponCode] = useState("");
  const router = useRouter();
  const [smallModalOpen, setSmallModalOpen] = useState(false);
  const { hasHadATrial } = useSubscriptionPermission();
  const { authStore } = useStores();
  const {
    displayCurrency,
    updateProgress,
    logOnboardingStep,
    currentStepIndex,
    otherObjective,
    otherText,
    otherCategory,
  } = useAuthContext();
  const routerRef = useRef(router);

  const [prices, setPrices] = useState([]);
  const [loadingPrices, setLoadingPrices] = useState(true);

  useEffect(() => {
    async function fetchPrices() {
      try {
        const res = await fetch(`/api/plus/prices`);
        const data = await res.json();

        if (!data || !data.prices || data.prices.length === 0) {
          console.error("No prices found!");
          return;
        }

        setPrices(data.prices);
      } catch (error) {
        console.error("Failed to fetch prices:", error);
      } finally {
        setLoadingPrices(false);
      }
    }

    fetchPrices();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("couponCode")) {
      setCouponCode(sessionStorage.getItem("couponCode"));
    }
    updateProgress("onboardingChooseYourPlan", true, true);
  }, []);

  const handleSmallPopupClose = () => setSmallModalOpen(false);

  const handleClose = () => {
    setSmallModalOpen(false);
    routerRef.current.push("/", "/", { shallow: true });
  };

  return couponCode ? (
    <>
      <Loading />
      <div className="hidden">
        <SubscriptionCards
          prices={prices}
          hasHadATrial={hasHadATrial}
          couponCode={couponCode}
          authStore={authStore}
          displayCurrency={displayCurrency}
          currentStepIndex={currentStepIndex}
          logOnboardingStep={logOnboardingStep}
          otherObjective={otherObjective}
          otherText={otherText}
          otherCategory={otherCategory}
        />
      </div>
    </>
  ) : (
    <>
      <div className="flex w-full flex-col gap-6">
        <h1 className={clsx("heading-md--spatial")}>
          Get the full Mob experience today
        </h1>
        {loadingPrices ? (
          <Loading />
        ) : (
          <SubscriptionCards
            prices={prices}
            hasHadATrial={hasHadATrial}
            couponCode={couponCode}
            authStore={authStore}
            displayCurrency={displayCurrency}
            currentStepIndex={currentStepIndex}
            logOnboardingStep={logOnboardingStep}
            otherObjective={otherObjective}
            otherText={otherText}
            otherCategory={otherCategory}
          />
        )}
      </div>

      <ConfirmDialog
        open={smallModalOpen}
        onOpenChange={setSmallModalOpen}
        title="Are you sure?"
        description="Sign up for a Premium account to get unlimited access to our recipes, meal plans and so much more."
        confirmLabel="Choose Plan"
        cancelLabel="Close"
        onConfirm={handleSmallPopupClose}
        onCancel={handleClose}
      />
    </>
  );
});
